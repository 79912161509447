import * as React from 'react'
import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { S_Div } from './style'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [token, setToken] = useState()
  const [severity, setSeverity] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setToken(params.get('token'))
    console.log(token)
    // Faire quelque chose avec la valeur du token
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    // Vérifier si les mots de passe sont identiques
    if (newPassword !== confirmNewPassword) {
      setPasswordsMatch(false)
      return
    } else if (passwordsMatch) {
      let formData = new FormData(event.target)

      formData.set('token', token)

      fetch('https://agenda.cc-deuxrives.fr/users/reset_password', {
        method: 'POST',
        body: formData,
        credentials: 'include',
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          if (json.status === 'ok') {
            setSeverity('success')
            setOpen(true)
            setNewPassword('')
            setConfirmNewPassword('')
          } else {
            setSeverity('error')
            setOpen(true)
          }
        })
    }
    // Envoyer la requête pour réinitialiser le mot de passe
    // ...
  }

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value)
    setPasswordsMatch(event.target.value === confirmNewPassword)
  }

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value)
    setPasswordsMatch(event.target.value === newPassword)
  }

  return (
    <>
      <S_Div onSubmit={handleSubmit}>
        <TextField
          style={{ marginRight: '20px', marginBottom: '20px' }}
          id="new-password-input"
          name="newPassword"
          value={newPassword}
          onChange={handleNewPasswordChange}
          type="password"
          label="Nouveau mot de passe"
          required
        />
        <TextField
          id="confirm-new-password-input"
          name="confirmNewPassword"
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
          type="password"
          label="Confirmer le nouveau mot de passe"
          required
          error={!passwordsMatch}
          helperText={
            !passwordsMatch && 'Les mots de passe ne correspondent pas'
          }
        />
        <Button
          style={{
            height: '48px',
            margin: 'auto',
            marginTop: '15px',
            display: 'block',
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Valider
        </Button>
      </S_Div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {severity == 'success'
            ? 'Réinitialisation du mot de passe effectué, vous pouvez maintenant retourner sur la page de connexion.'
            : 'Lien de réinitialisation périmé ou déjà utilisé'}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ResetPassword
