import { Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/Home'
import Admin from './pages/Admin'
import SignUp from './pages/SignUp'
import Login from './pages/Login'
import Error from './pages/Error'
import GlobalStyle from './styles/GlobalStyle'
import Header from './components/Header'
import NavBar from './components/NavBar'
import { UserContext } from './utils/context'
import { useContext, useEffect } from 'react'
import Profil from './pages/Profil'
import ResetPassword from './pages/ResetPassword'

function App() {
  const { user, changeUserState } = useContext(UserContext)
  const isAuth = () => {
    fetch('https://agenda.cc-deuxrives.fr/users/is_auth', {
      credentials: 'include',
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        if (data.access === 'granted') {
          changeUserState(data)
        }
      })
  }

  useEffect(() => {
    isAuth()
  }, [])

  useEffect(() => {}, [user])

  return (
    <>
      <GlobalStyle />
      <Header />
      <NavBar title="agenda des événements" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route
          path="/connexion"
          element={
            user.access === 'granted' ? <Navigate to="/" replace /> : <Login />
          }
        />
        <Route
          path="/inscription"
          element={
            user.access === 'granted' ? <Navigate to="/" replace /> : <SignUp />
          }
        />
        <Route
          path="/profil"
          element={
            user.access !== 'granted' ? <Navigate to="/" replace /> : <Profil />
          }
        />
        <Route
          path="/admin"
          element={
            user.access === 'granted' && user.role === 'administrateur' ? (
              <Admin />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  )
}

export default App
