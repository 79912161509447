import { ButtonCc2r } from '../../components/Button'
import { S_Div, S_Title } from './style'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle'
import KeyIcon from '@mui/icons-material/Key'
import Diversity2Icon from '@mui/icons-material/Diversity2'
import { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import BadgeIcon from '@mui/icons-material/Badge'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import { MuiTelInput } from 'mui-tel-input'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { useNavigate } from 'react-router-dom'
import { forwardRef } from 'react'
import { useEffect } from 'react'
import { UserContext } from '../../utils/context'
import { useContext } from 'react'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Profil = () => {
  let navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState()
  const { user, changeUserState } = useContext(UserContext)

  useEffect(() => {
    loadUsers()
  }, [user])

  useEffect(() => {}, [users])

  const loadUsers = async () => {
    setIsLoading(true)
    return fetch('https://agenda.cc-deuxrives.fr/users/list', {
      credentials: 'include',
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        if (data.length) {
          let currentUser = data.find(
            (lUser) => lUser.username.toLowerCase() === user.user.toLowerCase()
          )
          setUsers(currentUser)
          handleChange('', 'password')
          handleChange('', 'confirm_password')
          setIsLoading(false)
        }
      })
  }

  const handleChange = (value, name) => {
    if (name === 'password' || 'confirm_password') setIsFailed(false)
    setUsers((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault()

    let formData = new FormData(e.target)

    let formatPhone = users.telephone.split(' ')

    let phoneData = formatPhone.join('')

    formData.set('telephone', phoneData)

    if (users.password === users.confirm_password) {
      fetch('https://agenda.cc-deuxrives.fr/users/update', {
        method: 'POST',
        body: formData,
        credentials: 'include',
      })
        .then(function (response) {
          return response
        })
        .then(function (json) {
          setIsLoading(false)
          setOpen(true)
        })
    } else {
      setIsFailed(true)
      setIsLoading(false)
    }
  }

  return (
    <>
      <S_Div onSubmit={handleSubmit}>
        <Oval
          wrapperClass="spinner"
          height={80}
          width={80}
          color="#e66c86"
          visible={isLoading}
          ariaLabel="oval-loading"
          secondaryColor="#29a78c"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        <S_Title>Profil</S_Title>
        <div
          className="login-wrapper"
          style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
        >
          <TextField
            name="username"
            value={users?.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
            label="Nom d'utilisateur"
            required
          ></TextField>
          <TextField
            value={users?.entite}
            onChange={(e) => handleChange(e.target.value, 'entite')}
            name="entite"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Diversity2Icon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Entité"
            required
          ></TextField>
          <TextField
            name="name"
            value={users?.name}
            onChange={(e) => handleChange(e.target.value, 'name')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BadgeIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Nom"
            // helperText="Mot de passe erroné"
            required
          ></TextField>
          <TextField
            value={users?.surname}
            onChange={(e) => handleChange(e.target.value, 'surname')}
            name="surname"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BadgeIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Prénom"
            // helperText="Mot de passe erroné"
            required
          ></TextField>
          <TextField
            name="adresse1"
            value={users?.adresse1 || ''}
            onChange={(e) => handleChange(e.target.value, 'adresse1')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Adresse 1"
            // helperText="Mot de passe erroné"
          ></TextField>
          <TextField
            name="adresse2"
            value={users?.adresse2 || ''}
            onChange={(e) => handleChange(e.target.value, 'adresse2')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Adresse 2"
            // helperText="Mot de passe erroné"
          ></TextField>
          <TextField
            name="ville"
            value={users?.ville || ''}
            onChange={(e) => handleChange(e.target.value, 'ville')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Ville"
            // helperText="Mot de passe erroné"
          ></TextField>
          <TextField
            name="codepostal"
            value={users?.codepostal || ''}
            onChange={(e) => handleChange(e.target.value, 'codepostal')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Code Postal"
            // helperText="Mot de passe erroné"
          ></TextField>
          <MuiTelInput
            variant="standard"
            label="Téléphone"
            name="telephone"
            value={users?.telephone || ''}
            onChange={(value) => handleChange(value, 'telephone')}
            onlyCountries={['FR']}
            defaultCountry={'FR'}
          />
          <TextField
            name="email"
            value={users?.email}
            onChange={(e) => handleChange(e.target.value, 'email')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="email"
            label="Email"
            // helperText="Mot de passe erroné"
            required
          ></TextField>
          <TextField
            error={isFailed}
            name="password"
            value={users?.password}
            onChange={(e) => handleChange(e.target.value, 'password')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="password"
            label="Nouveau Mot de passe"
            // helperText="Mot de passe erroné"
          ></TextField>
          <TextField
            error={isFailed}
            value={users?.confirm_password}
            onChange={(e) => handleChange(e.target.value, 'confirm_password')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="password"
            label="Confirmer Le Mot de passe"
            helperText={
              isFailed
                ? 'Les mots de passe saisis ne sont pas identiques.'
                : null
            }
            // helperText="Mot de passe erroné"
          ></TextField>
        </div>
        <div
          className="validation-btn"
          style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
        >
          <ButtonCc2r style={{ height: '48px' }} type="submit" variant="green">
            Valider
          </ButtonCc2r>
        </div>
      </S_Div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Modifications enregistrées'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Vos modifications ont bien été prises en compte.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonCc2r onClick={handleClose}>Fermer</ButtonCc2r>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Profil
