import * as React from 'react'
import { ButtonCc2r } from '../../components/Button'
import { S_Div, S_Title } from './style'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle'
import KeyIcon from '@mui/icons-material/Key'
import { UserContext } from '../../utils/context'
import { useContext, useState } from 'react'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import { Oval } from 'react-loader-spinner'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const Login = () => {
  const { changeUserState } = useContext(UserContext)
  const [isFailed, setIsFailed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [email, setEmail] = useState('')
  const [open, setOpen] = React.useState(false)
  const [severity, setSeverity] = React.useState(false)

  const handleLinkClick = () => {
    setShowForm(true)
  }

  const handleLinkClickClose = () => {
    setShowForm(false)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleSubmitReset = (event) => {
    event.preventDefault()

    let formData = new FormData(event.target)

    fetch('https://agenda.cc-deuxrives.fr/users/lost_password', {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (json) {
        setIsLoading(false)
        if (json.status === 'ok') {
          setSeverity('success')
          setOpen(true)
        } else {
          setSeverity('error')
          setOpen(true)
        }
      })
    setEmail('')
    setShowForm(false)
  }

  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault()

    let formData = new FormData(e.target)

    fetch('https://agenda.cc-deuxrives.fr/users/login', {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (json) {
        setIsLoading(false)
        if (json.access === 'granted') {
          changeUserState(json)
        } else {
          setIsFailed(true)
        }
      })
  }

  return (
    <>
      <S_Div
        onSubmit={handleSubmit}
        style={showForm ? { opacity: '0.5' } : { opacity: '1' }}
      >
        <Oval
          wrapperClass="spinner"
          height={80}
          width={80}
          color="#e66c86"
          visible={isLoading}
          ariaLabel="oval-loading"
          secondaryColor="#29a78c"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        <S_Title>Connexion</S_Title>
        <div
          className="login-wrapper"
          style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
        >
          <TextField
            error={isFailed}
            name="username"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Nom d'utilisateur"
            helperText={isFailed ? 'Identifiants érronés' : null}
            required
          ></TextField>
          <TextField
            error={isFailed}
            name="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="password"
            label="Mot de passe"
            // helperText="Mot de passe erroné"
            required
          ></TextField>
        </div>
        <div
          className="validation-btn"
          style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
        >
          <ButtonCc2r style={{ height: '48px' }} type="submit" variant="green">
            Valider
          </ButtonCc2r>
        </div>
      </S_Div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        {showForm ? (
          <>
            <p>
              Si l'adresse mail est valide, un mail contenant votre nom
              d'utilisateur et un lien de réinitialisation de mot de passe vous
              sera envoyé.
              <a href="#" onClick={handleLinkClickClose}>
                {' '}
                Annuler
              </a>
            </p>
            <form onSubmit={handleSubmitReset}>
              <TextField
                id="email-input"
                name="email"
                value={email}
                onChange={handleEmailChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailIcon />
                    </InputAdornment>
                  ),
                }}
                type="email"
                label="Email"
                // helperText="Mot de passe erroné"
                required
              ></TextField>
              <ButtonCc2r
                style={{ height: '48px', margin: 'auto', marginTop: '15px' }}
                type="submit"
                variant="green"
              >
                Valider
              </ButtonCc2r>
            </form>
          </>
        ) : (
          <p>
            Mot de passe ou nom d'utilisateur oublié ?
            <a href="#" onClick={handleLinkClick}>
              {' '}
              Cliquez ici
            </a>
          </p>
        )}
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {severity == 'success'
            ? 'La demande a bien été prise en compte, consultez vos mails.'
            : 'Erreur'}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Login
