import { ButtonCc2r, NavLinkCc2r } from '../Button'
import { S_Nav, S_Title, S_NavLinks } from './style'
import { UserContext } from '../../utils/context'
import { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

const NavBar = ({ title }) => {
  const { user, changeUserState } = useContext(UserContext)
  const location = useLocation()

  useEffect(() => {}, [user])

  const handleLogout = () => {
    fetch('https://agenda.cc-deuxrives.fr/users/logout', {
      credentials: 'include',
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        if (data.access === 'disconnected') {
          changeUserState({})
        }
      })
  }

  return (
    <>
      <S_Nav>
        <S_Title>{title}</S_Title>
        <div className="login-wrapper">
          {user.access !== 'granted' && (
            <Link style={{ textDecoration: 'none' }} to={`/inscription`}>
              <ButtonCc2r style={{ textDecoration: 'underline' }}>
                Inscription
              </ButtonCc2r>
            </Link>
          )}
          {user.access !== 'granted' && (
            <Link style={{ textDecoration: 'none' }} to={`/connexion`}>
              <ButtonCc2r variant="green">Connexion</ButtonCc2r>
            </Link>
          )}
          {user.access === 'granted' && (
            <div className="username">{user.user}</div>
          )}
          {user.access === 'granted' && (
            <ButtonCc2r onClick={handleLogout}>Déconnexion</ButtonCc2r>
          )}
        </div>
      </S_Nav>
      <S_NavLinks>
        {location.pathname !== '/' && (
          <Link style={{ textDecoration: 'none' }} to={`/`}>
            <NavLinkCc2r>Accueil</NavLinkCc2r>
          </Link>
        )}
        {location.pathname !== '/admin' && user.role === 'administrateur' && (
          <Link style={{ textDecoration: 'none' }} to={`/admin`}>
            <NavLinkCc2r>Dashboard</NavLinkCc2r>
          </Link>
        )}
        {user.access === 'granted' && location.pathname !== '/profil' && (
          <Link style={{ textDecoration: 'none' }} to={`/profil`}>
            <NavLinkCc2r>Profil</NavLinkCc2r>
          </Link>
        )}
      </S_NavLinks>
    </>
  )
}

export default NavBar
